@import "src/styles/common";

.list {
  list-style: inside;
}


.link {
  @include link();
}

.text {
  margin-bottom:12pt;
  text-align:justify;
  line-height:normal;
  font-size:12pt
}
