// colors
:root {
  --white:  rgba(255, 255, 255, 1);
  --dark-white: rgba(255, 255, 255, 0.4);
  --yellow: rgba(252, 199, 67, 1);
  --light-yellow: rgba(249, 223, 116, 1);
}

// font
$font-weight-map: (
        'light': 300,
        'regular': 400,
        'medium': 500,
        'semibold': 600,
        'bold': 700,
);

$title1: 61px, 60px;
$title2: 20px, 32px;
$title3: 4rem, 100%;

$text1: 17px, 24px;
$text2: 16px, 24px;
$text3: 13px, 16px;
$text4: 24px, 40px;
$text5: 1.4rem, 100%;

$font-type-map: (
        'h1':$title1,
        'h2':$title2,
        'h3':$title3,
        'p1':$text1,
        'p2':$text2,
        'p3':$text3,
        'p4':$text4,
        'p5':$text5,
);

// border
$border-radius-map: (
        'small': 6px,
        'medium': 12px,
        'large': 20px,
);

// other
