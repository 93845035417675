@import "src/styles/common";

.home {
  &__content {
    padding: 25px;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 920px) {
      padding: 25px 0;
      display: block;
      height: auto;
    }
  }

  &__info {
    flex-basis: 60%;
    min-width: 748px;
    order: 1;

    @media (max-width: 1240px) {
      min-width: 652px;
    }

    @media (max-width: 1024px) {
      min-width: 542px;
    }

    @media (max-width: 920px) {
      min-width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__infoWrap {
    width: max-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  &__image {
    flex-basis: 40%;
    order: 2;
    height: 90%;

    @media (max-width: 920px) {
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    & img {
      width: auto;
      height: 100%;
      display: block;
      object-fit: contain;

      @media (max-width: 920px) {
        max-height: 300px;
        text-align: center;
      }
    }
  }

  &__logo {
    margin: 0 0 20px 0;
    @include font(medium, p5);
    color: lightyellow;
    letter-spacing: -1px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    & img {
      width: 20px;
      height: 20px;
    }
  }

  &__title {
    margin: 0 0 16px 0;
    @include font(medium, h3);
    color: white;
    letter-spacing: -4px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__descr {
    margin: 0 0 32px 0;
    @include font(regular, p5);
    color: white;
    letter-spacing: -1px;
  }

  &__wrapper {
    display: flex;
    align-items: center;
  }

  &__link {
    width: 108px;
    height: 33px;

    &:not(:last-child) {
      margin-right: 16px;
    }

    & img {
      width: 100%;
      height: 100%;
    }

    @media (max-width: 920px) {
      width: 80px;
      height: 25px;
    }
  }
}
