@font-face {
    font-family: 'DrukCyr-MediumItalic';
    src: local('MyFont'), url(../../assets/DrukCyr-MediumItalic.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}

body {
    background-color: #F6F6F7;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
