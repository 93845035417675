@use "variables";

@mixin font($weight: regular, $type) {
  $font-type: map-get(variables.$font-type-map, $type);

  font-weight: map-get(variables.$font-weight-map, $weight);
  font-size: nth($font-type, 1);
  line-height: nth($font-type, 2);
}

@mixin border-radius($radius) {
  border-radius: map-get(variables.$border-radius-map, $radius);
}

@mixin link {
  position: relative;
  @include font(regular, p2);
  letter-spacing: 0;
  transition: color 0.3s ease-in-out;

  &::after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: var(--light-yellow);
    transition: background-color 0.3s ease-in-out;
  }

  &:hover {
    color: var(--yellow);

    &::after {
      background-color: var(--yellow);
    }
  }
}
