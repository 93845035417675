@import "src/styles/common";

.descr {
  @include font(regular, p2);
  letter-spacing: 0;
}

.link {
  @include link();
}
