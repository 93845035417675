@import "src/styles/common";

.title {
  @include font(medium, h2);
}

.subtitle {
  display: block;
  @include font(medium, p2);
}

.info {
  @include font(regular, p2);
  letter-spacing: 0;
}


.link {
  @include link();
}
