@import "variables", "mixin";

html {
  box-sizing: border-box;
  scroll-behavior: smooth;

  @media (max-width: 1240px) {
    font-size: 14px;
  };

  @media (max-width: 1024px) {
    font-size: 11px;
  };

  @media (max-width: 640px) {
    font-size: 8px;
  };
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

img {
  max-width: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

body {
  position: relative;
  min-width: 320px;
  font-family: Inter, sans-serif;
  @include font(regular, p1);
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;

  &[background="home"] {
    background-image: url("~assets/images/mainBackground.png");
  }

  &[background="light"] {
    background-image: none;
    background-color: white;
    color: #292A3B;
  }

  &[background="dark"] {
    background-image: none;
    background-color: #292A3B;
    color: white;
  }
}

:active,
:hover,
:focus,
:focus-visible {
  outline: none;
  outline-offset: 0;
}

.visually-hidden {
  visibility: hidden;
  pointer-events: none;
  position: absolute;
}

.stop-scroll {
  overflow: hidden;
}

button {
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

ul, ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

p {
  margin: 0;
}

.container {
  max-width: 1660px;
  padding: 0 30px;
  margin: 0 auto;

  @media (max-width: 920px) {
    padding: 0 15px;
  }
}

.section {
  padding: 34px 0;
}

.mb {
  margin: 0 0 16px 0;
}

.mb-large {
  margin: 0 0 20px 0;
}

.mb-small {
  margin: 0 0 8px 0;
}

.fw {
  font-weight: 500;
}
