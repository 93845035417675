@import "src/styles/common";

.item {
  &:not(:last-child) {
    margin-bottom: 42px;
  }

  &__title {
    margin: 0;
    @include font(bold, p4);
    letter-spacing: 0;
  }

  &__subtitle {
    margin: 0;
    @include font(regular, p2);
    letter-spacing: 0;
  }

  &__link {
    @include link();
  }

  &__type {
    margin: 0;
    @include font(regular, p2);
    letter-spacing: 0;
  }
}
